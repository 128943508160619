import React from "react";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  userName: string;
}

const Header: React.FC<HeaderProps> = ({ userName }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    document.cookie = "auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    navigate("/");
  };

  return (
    <header className="header">
      <h1>Witaj {userName}</h1>
      <button onClick={handleLogout} className="logout-button right">Logout</button>
    </header>
  );
};

export default Header;