import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Reservations.css";
import Chat from "../Chat/Chat";
import Header from "./Components/Header";
import UserList from "./Components/UserList";
import AvailabilityForm from "./Components/AvailabilityForm";
import { User, Reservation, DailyReservation } from "./Interfaces";

const getUsers = async (): Promise<User[]> => {
  try {
    const response = await fetch("https://kiedy5.com/api/User", {
      method: "GET",
      headers: { "Content-Type": "application/json" }
    });
    if (!response.ok) throw new Error(`Failed to fetch users: ${response.statusText}`);
    return await response.json();
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

const getReservations = async (): Promise<DailyReservation> => {
  try {
    const response = await fetch("https://kiedy5.com/api/Reservations/today", {
      method: "GET",
      headers: { "Content-Type": "application/json" }
    });
    if (!response.ok) throw new Error(`Failed to fetch reservations: ${response.statusText}`);
    return await response.json();
  } catch (error) {
    console.error("Error fetching reservations:", error);
    throw error;
  }
};

const addReservation = async (user: User, availability: { from: string; to: string }): Promise<void> => {
  try {
    const response = await fetch("https://kiedy5.com/api/Reservations", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userName: user.name,
        date: new Date().toISOString().split("T")[0],
        from: availability.from,
        to: availability.to
      })
    });
    if (!response.ok) throw new Error(`Failed to add reservation: ${response.statusText}`);
  } catch (error) {
    console.error("Error adding reservation:", error);
  }
};

const removeReservation = async (userName: string): Promise<void> => {
  try {
    const response = await fetch(`https://kiedy5.com/api/Reservations/${userName}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" }
    });
    if (!response.ok) throw new Error(`Failed to remove reservation: ${response.statusText}`);
  } catch (error) {
    console.error("Error removing reservation:", error);
  }
};

const Reservations: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user }: { user: User } = location.state || {};

  const [users, setUsers] = useState<User[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [availability, setAvailability] = useState<{ from: string; to: string }>({ from: "", to: "" });
  const [readyCount, setReadyCount] = useState(0);
  const [reservationTime, setReservationTime] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersData = await getUsers();
        const reservationsData = await getReservations();

        const updatedUsers = usersData.map((u) => {
          const userReservation = reservationsData.reservations.find(
            (res) => res.userName.toLowerCase() === u.name.toLowerCase()
          );
          if (userReservation) {
            return {
              ...u,
              isReady: true,
              availability: `${userReservation.from} - ${userReservation.to}`
            };
          }
          return u;
        });

        setUsers(updatedUsers);

        if (user) {
          const currentUser = updatedUsers.find((u) => u.name.toLowerCase() === user.name.toLowerCase());
          if (currentUser) {
            navigate(".", { state: { user: currentUser } });
          }
        }

        const readyUsers = updatedUsers.filter(u => u.isReady);
        setReadyCount(readyUsers.length);

        if (readyUsers.length >= 2) {
          const firstUser = readyUsers[0];
          const lastUser = readyUsers[readyUsers.length - 1];

          if (firstUser.availability && lastUser.availability) {
            const firstUserToTime = new Date(`1970-01-01T${firstUser.availability.split(" - ")[1]}:00`);
            const lastUserFromTime = new Date(`1970-01-01T${lastUser.availability.split(" - ")[0]}:00`);

            if (lastUserFromTime <= firstUserToTime) {
              setReservationTime(lastUserFromTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }));
            } else {
              setReservationTime(firstUserToTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }));
            }
          }
        }

      } catch (error) {
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleReady = async () => {
    if (user) {
      const currentTime = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
      const fromTime = availability.from || currentTime;
      const updatedUser = { ...user, availability: `${fromTime} - ${availability.to}`, isReady: true };
      await addReservation(updatedUser, { from: fromTime, to: availability.to });

      setUsers(prevUsers => {
        const newUsers = prevUsers.map((u) => u.id === user.id ? updatedUser : u);
        const readyUsersCount = newUsers.filter(u => u.isReady).length;
        setReadyCount(readyUsersCount);

        if (readyUsersCount >= 2) {
          setReservationTime(`${fromTime}`);
        }

        return newUsers;
      });

      navigate(".", { state: { user: updatedUser } }); // Update `user` state in location
    }
  };

  const handleCancel = async () => {
    if (user) {
      await removeReservation(user.name);

      const updatedUser = { ...user, availability: "", isReady: false };
      setUsers(prevUsers => {
        const newUsers = prevUsers.map((u) => u.id === user.id ? updatedUser : u);
        const readyUsersCount = newUsers.filter(u => u.isReady).length;
        setReadyCount(readyUsersCount);

        if (readyUsersCount < 2) {
          setReservationTime(null);
        }

        return newUsers;
      });

      navigate(".", { state: { user: updatedUser } }); // Update `user` state in location
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="reservations">
      <Header userName={user?.name || "Unknown User"} />
      <div className="reservation-section">
        <h1>Rezerwacja {readyCount >= 2 && reservationTime ? `(${reservationTime})` : ""}</h1>
      </div>
      <main className="main-content">
        <UserList users={users} />
        <div className="middle-column">
          <AvailabilityForm
            availability={availability}
            setAvailability={setAvailability}
            isReady={user?.isReady}
            handleReady={handleReady}
            handleCancel={handleCancel}
          />
        </div>
        <div className="right-column">
          {/* <Chat userName={user?.name || "Unknown User"} /> */}
        </div>
      </main>
    </div>
  );
};

export default Reservations;