import React from "react";
import UserBar from "./UserBar";

interface User {
  id: string;
  name: string;
  city: string;
  nickname: string;
  distanceRange: string;
  isActive: boolean;
  isReady: boolean;
  availability?: string;
}

interface UserListProps {
  users: User[];
}

const UserList: React.FC<UserListProps> = ({ users }) => (
  <div className="left-column">
    <h3>Tibiorze</h3>
    {users.map((user) => (
      <UserBar key={user.id} user={user} />
    ))}
  </div>
);

export default UserList;