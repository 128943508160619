import React from "react";

interface AvailabilityFormProps {
  availability: { from: string; to: string };
  setAvailability: (availability: { from: string; to: string }) => void;
  isReady: boolean;
  handleReady: () => void;
  handleCancel: () => void;
}

const AvailabilityForm: React.FC<AvailabilityFormProps> = (
  {
    availability,
    setAvailability,
    isReady,
    handleReady,
    handleCancel
  }) => (
  <div className="availability-section">
    <h3>Od której gotowość?</h3>
    <div className="inputs-container">
      <div className="input-group">
        <label htmlFor="start-time">Od:</label>
        <input
          type="time"
          id="start-time"
          name="start-time"
          required
          value={availability.from}
          disabled={isReady}
          onChange={(e) => setAvailability({ ...availability, from: e.target.value })}
        />
      </div>
      <div className="input-group">
        <label htmlFor="end-time">Do:</label>
        <input
          type="time"
          id="end-time"
          name="end-time"
          required
          value={availability.to}
          disabled={isReady}
          onChange={(e) => setAvailability({ ...availability, to: e.target.value })}
        />
      </div>
    </div>
    <div className="button-group">
      {isReady ? (
        <button onClick={handleCancel} className="cancel-button">Anuluj</button>
      ) : (
        <button onClick={handleReady} className="ready-button">Gotowy</button>
      )}
    </div>
  </div>
);

export default AvailabilityForm;