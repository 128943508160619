import React from "react";
import userImage from "../../resources/discord.png";

interface User {
  id: string;
  name: string;
  city: string;
  nickname: string;
  distanceRange: string;
  isActive: boolean;
  isReady: boolean;
  availability?: string;
}

interface UserBarProps {
  user: User;
}

const UserBar: React.FC<UserBarProps> = ({ user }) => (
  <div className={`user-bar ${user.isReady ? "ready" : ""}`}>
    <img src={userImage} alt="User" className="user-image" />
    <div className="user-info">
      <p><strong>Name:</strong> {user.name}</p>
      <p><strong>City:</strong> {user.city}</p>
      <p><strong>Nickname:</strong> {user.nickname}</p>
      {user.isReady && <p><strong>Availability:</strong> {user.availability}</p>}
    </div>
  </div>
);

export default UserBar;