import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../resources/login_pic.png";
import "../Login/Login.css";

const determineUser = async (latitude, longitude) => {
  const response = await fetch("https://kiedy5.com/api/User/determineUser", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ latitude, longitude })
  });

  if (!response.ok) {
    throw new Error("Failed to determine user");
  }

  return response.json();
};

const Login = () => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      }, (error) => {
        setError("Unable to retrieve your location");
        console.error(error);
      });
    } else {
      setError("Geolocation is not supported by your browser");
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!latitude || !longitude) {
      setError("Location data is not available");
      return;
    }

    if (password === "fujara12") {
      try {
        const data = await determineUser(latitude, longitude);
        console.log("User data:", data);

        document.cookie = "auth=tibiorz; path=/; max-age=3600"; // Cookie valid for 1 hour

        navigate("/rezerwacje", { state: { user: data } });
      } catch (error) {
        console.error("Error:", error);
        setError("An error occurred during login");
      }
    } else {
      setError("Incorrect password");
    }
  };

  const handleGetCoordinates = () => {
    if (!navigator.geolocation) {
      console.error("Geolocation is not supported by your browser");
      return;
    }
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    }, (error) => {
      console.error("Unable to retrieve your location", error);
    });
  };

  return (
    <div className="App">
      <header>
        <h1>Kiedy5</h1>
      </header>
      <div className="contentWrapper">
        <div className="mainBlock">
          <aside className="photoSection">
            <img src={logo} className="App-logo" alt="logo" />
          </aside>
          <main className="loginSection">
            <h2>34.Tibia</h2>
            <form onSubmit={handleLogin}>
              <input
                type="password"
                placeholder="Hasło"
                className="App-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="submit" className="App-button">Login</button>
            </form>
            {error && <p className="error">{error}</p>}
          </main>
        </div>
      </div>
      <button onClick={handleGetCoordinates} className="App-button">Get Coordinates</button>
    </div>
  );
};

export default Login;